import React, { useCallback, useEffect, useState, useRef } from 'react';
import { QUERY_KEYS } from '~constants/query-keys';
import { useQuery } from '@tanstack/react-query';
import { getDataInLandingPage } from '~api/users';

import HeroSection from './hero-section';
import ArtistsLiveBoard from './artists-live-board';
import FeaturedUpcomingEvents from './featured-upcoming-events';
import FeaturedPastEvents from './featured-past-events';
import { useSocket } from '~hooks/useSocket';
import {
  SocketContext,
  useGlobalDispatch,
  useGlobalState,
} from '~utils/container';
import { WebSocketEvent } from '~socket/types';
import { Title, Wrapper } from '~components/pages/home/styles';
import { Box } from '@mui/material';
import { toUnixInteger } from '~utils/dateTimeUtils';
import { getBannerEvents } from '~api/event-banners';

const Home = () => {
  const [totalOngoingEvent, setTotalOngoingEvent] = useState(0);
  const [totalUpcomingEvent, setTotalUpcomingEvent] = useState(0);
  const [totalEndedEvent, setTotalEndedEvent] = useState(0);
  const globalState = useGlobalState();
  const dispatch = useGlobalDispatch();
  const socket = useSocket('', dispatch) || null;
  const { socket_event } = globalState as Record<string, any>;
  const agoraName = useRef(`guest__${toUnixInteger()}`);

  const { refetch: refetchLandingPageData } = useQuery({
    queryKey: [QUERY_KEYS.LANDING_PAGE_DATE],
    queryFn: getDataInLandingPage,
    onSuccess: (data) => {
      setTotalOngoingEvent(data.totalOngoingEvent);
      setTotalUpcomingEvent(data.totalUpcomingEvent);
      setTotalEndedEvent(data.totalEndedEvent);
    },
    refetchOnWindowFocus: false,
  });

  const { data: bannerEvents } = useQuery({
    queryKey: [QUERY_KEYS.LANDING_PAGE_BANNER_EVENTS],
    queryFn: getBannerEvents,
    refetchOnWindowFocus: false,
  });

  const onWelcome = (data: any) => {
    console.log(`Ladingpage: socket session=${data?.session}`);
  };
  const onStartEvent = async () => {
    await refetchLandingPageData();
  };

  const onEndEvent = async () => {
    await refetchLandingPageData();
  };
  const onDeleteEvent = async () => {
    setTotalUpcomingEvent((prevState) => {
      return prevState >= 1 ? prevState - 1 : 0;
    });
  };
  const onAddedEvent = async (data: any) => {
    const totalAddedEvents = data.total_added_events || 0;
    setTotalUpcomingEvent((prevState) => prevState + totalAddedEvents);
  };

  const getProcessEventFunction = useCallback((event: string) => {
    const map: { [key: string]: (data: any) => Promise<void> | void } = {
      [WebSocketEvent.WELCOME]: onWelcome,
      [WebSocketEvent.START_EVENT]: onStartEvent,
      [WebSocketEvent.END_EVENT]: onEndEvent,
      [WebSocketEvent.DELETE_EVENT]: onDeleteEvent,
      [WebSocketEvent.ADDED_EVENTS]: onAddedEvent,
    };
    return map[event];
  }, []);

  useEffect(() => {
    if (socket_event) {
      const { event } = socket_event;
      console.log('socket_event', socket_event);
      const processEventFunction = getProcessEventFunction(event);
      processEventFunction && processEventFunction(socket_event);
    }
  }, [getProcessEventFunction, socket_event]);

  return (
    <SocketContext.Provider value={socket}>
      {!totalOngoingEvent && (
        <HeroSection eventBanners={bannerEvents?.eventBanners || []} />
      )}
      {totalOngoingEvent ? (
        <ArtistsLiveBoard
          agoraName={agoraName.current}
          total={totalOngoingEvent}
        />
      ) : (
        <Box sx={{ height: 30 }}></Box>
      )}
      {!!totalUpcomingEvent && (
        <Wrapper>
          <Title>Featured Upcoming Events</Title>
          <FeaturedUpcomingEvents total={totalUpcomingEvent} />
        </Wrapper>
      )}
      {totalEndedEvent > 0 && (
        <Wrapper sx={{ mb: { xs: 15, sm: 7.5 } }}>
          <Title>Featured Past Events</Title>
          <FeaturedPastEvents total={totalEndedEvent} />
        </Wrapper>
      )}
    </SocketContext.Provider>
  );
};

export default Home;
